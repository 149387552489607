import(/* webpackMode: "eager", webpackExports: ["ApolloProvider"] */ "/home/runner/_work/core/core/apps/videos-admin/src/app/[locale]/_ApolloProvider/ApolloProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AuthProvider"] */ "/home/runner/_work/core/core/apps/videos-admin/src/libs/auth/AuthProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SnackbarProvider"] */ "/home/runner/_work/core/core/apps/videos-admin/src/libs/SnackbarProvider/SnackbarProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["UploadVideoVariantProvider","useUploadVideoVariant","CLOUDFLARE_R2_CREATE","CREATE_MUX_VIDEO_UPLOAD_BY_URL","CREATE_VIDEO_VARIANT","GET_MY_MUX_VIDEO"] */ "/home/runner/_work/core/core/apps/videos-admin/src/libs/UploadVideoVariantProvider/UploadVideoVariantProvider.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/_work/core/core/node_modules/@mui/material-nextjs/v13-appRouter/appRouterV13.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/_work/core/core/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
